import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';

export const state = () => ({
  couponLatestReferral: null,
});

export const getters = {
  couponLatestReferral: (state) => state.couponLatestReferral,
};

export const mutations = {
  SET_COUPON_LATEST_REFERRAL(state, data) {
    state.couponLatestReferral = data;
  },
};

export const actions = {
  async getCouponLatestReferral({ dispatch }) {
    const {
      data: {
        currentUser: { user },
      },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: CURRENT_USER_QUERY,
    });
    dispatch('setCouponLatestReferral', user.latest_referral_coupon);
  },
  setCouponLatestReferral({ commit, dispatch }, coupon) {
    dispatch('user/cart/setDiscountCode', coupon, { root: true });
    commit('SET_COUPON_LATEST_REFERRAL', coupon);
  },
};
