import { WILL_STATUS, ONBOARDING_STEP } from '@/utilities/constants';

export default async function ({ redirect, store, app, query }) {
  const userDetails = store.getters['user/contacts/userDetails'];
  const affiliate = store.state.application.affiliate;
  const affiliateSlug = affiliate.slug || 'sw';
  const steps = store.state['user-onboarding'].steps;
  const onboardingNeedsAssessmentPagesRequired =
    store.getters['user-onboarding/onboardingNeedsAssessmentPagesRequired'];
  const hasCompletedVaultItemStep =
    store.getters['user-onboarding/hasCompletedVaultItemStep'];
  const path = app.context.route.path;
  const isOnboardingPage = path.includes('/onboarding/user');
  const isWillNotStarted = store.getters.willStatus === WILL_STATUS.NOT_STARTED;

  const getRequiredOnboardingStepKey = async () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      residentialAddress: { country } = {},
    } = userDetails;
    const { affiliateUserIdEnabled, couponCodeEnabled } = affiliate.features;

    if (!firstName || !lastName) {
      return ONBOARDING_STEP.NAME;
    }
    if (!dateOfBirth) {
      return ONBOARDING_STEP.DOB;
    }
    if (
      couponCodeEnabled &&
      !store.getters['user/coupon/couponLatestReferral']
    ) {
      return ONBOARDING_STEP.DOB;
    }
    if (affiliateUserIdEnabled && !store.getters.affiliateUserId) {
      return ONBOARDING_STEP.DOB;
    }
    if (!country) {
      return ONBOARDING_STEP.COUNTRY;
    }
    if (onboardingNeedsAssessmentPagesRequired && isWillNotStarted) {
      await store.dispatch('user-onboarding/loadToolAndVaultItems');
      if (!hasCompletedVaultItemStep(ONBOARDING_STEP.FAMILY)) {
        return ONBOARDING_STEP.FAMILY;
      } else if (!hasCompletedVaultItemStep(ONBOARDING_STEP.ASSETS)) {
        return ONBOARDING_STEP.ASSETS;
      }
    }

    return null;
  };

  const key = await getRequiredOnboardingStepKey();

  const slug = key ? steps[key].slug : null;
  if (path.includes(steps[ONBOARDING_STEP.FINISH].slug) && isWillNotStarted) {
    return true;
  } else if (slug && `/${affiliateSlug}${slug}` !== path) {
    return redirect(app.localePath(`/${affiliateSlug}${slug}`), query);
  } else if (slug && `/${affiliateSlug}${slug}` === path) {
    store.dispatch('user-onboarding/initOnboarding', key);
  } else if (!slug && isOnboardingPage) {
    return redirect(app.localePath(`/${affiliateSlug}`), query);
  }
}
