import GET_CAUSES_BY_USER_SEGMENT from '@/graphql/queries/GetCausesByUserSegment';
import { enrichCauseData } from '@/utilities';

export const state = () => ({
  causes: [],
});

export const getters = {
  causes: (state) => state.causes,
};

export const mutations = {
  SET_CAUSES(state, data) {
    state.causes = data;
  },
};

export const actions = {
  async getCauses({ rootGetters, commit }) {
    if (
      !rootGetters['user/contacts/userDetails']?.residentialAddress?.country
    ) {
      return;
    }

    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_CAUSES_BY_USER_SEGMENT,
      variables: {
        userId: rootGetters.userId,
      },
    });
    const causesWithCharityMeta = data.getCausesByUserSegment.map((cause) =>
      enrichCauseData(cause)
    );
    commit('SET_CAUSES', causesWithCharityMeta);
  },
};
