import MASQUERADE from '@/graphql/mutations/Masquerade';
import GET_POA_BY_USER_ID from '@/graphql/queries/GetPoaByUserId';
import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';

export const state = () => ({
  isMasquerading: false,
});

export const getters = {
  isMasquerading: (state) => state.isMasquerading,
};

export const mutations = {
  SET_MASQUERADING(state, value) {
    state.isMasquerading = value;
  },
};

export const actions = {
  async enterMasquerade({ commit, dispatch }, { userId, willId, willStatus }) {
    try {
      const { data } = await this.app.apolloProvider.defaultClient.mutate({
        mutation: MASQUERADE,
        variables: {
          id: userId,
        },
      });

      const {
        data: { poaByUserId },
      } = await this.app.apolloProvider.defaultClient.query({
        query: GET_POA_BY_USER_ID,
        variables: {
          userId,
        },
      });
      if (poaByUserId) {
        dispatch('poa/setPoaId', poaByUserId.id, { root: true });
        dispatch('poa/getAllPoaRelatedData', null, { root: true });
      }

      window.$nuxt.$sensitive.DirectoryAddress.setMasqueradeUserId(
        data.masquerade.user.id
      );
      window.$nuxt.$sensitive.DirectoryPerson.setMasqueradeUserId(
        data.masquerade.user.id
      );

      for (const key in window.$nuxt.$sensitive.EndOfLifeItem) {
        window.$nuxt.$sensitive.EndOfLifeItem[key].setMasqueradeUserId(
          data.masquerade.user.id
        );
      }

      commit('SET_MASQUERADING', true);
      dispatch('setEmail', data.masquerade.user.email, { root: true });
      dispatch('setUserId', data.masquerade.user.id, { root: true });
      dispatch('setVerified', data.masquerade.user.verified, { root: true });
      dispatch('setWillId', willId, { root: true });
      dispatch('setWillStatus', willStatus, { root: true });

      await dispatch('application/prices/getProductPrices', null, {
        root: true,
      });
      await dispatch('product/getProducts', null, { root: true });
      await dispatch('user/coupon/getCouponLatestReferral', null, {
        root: true,
      });
      await dispatch('orders/getOrders', null, { root: true });
      await dispatch('user/cart/setDiscountCode', null, { root: true });

      await dispatch(
        'user/contacts/getDirectoryPersonsByOwnerId',
        {
          ownerId: data.masquerade.user.id,
          personId: data.masquerade.user?.person_id,
        },
        { root: true }
      );
      await dispatch('flag/getPlanFlags', { planId: willId }, { root: true });
      await dispatch('application/causes/getCauses', null, { root: true });
      this.$router.push({ path: this.localePath('/') });
    } catch (e) {
      console.error(e.message);
    }
  },
  async exitMasquerade({ commit, dispatch }) {
    try {
      const { data } = await this.app.apolloProvider.defaultClient.query({
        query: CURRENT_USER_QUERY,
      });

      commit('SET_MASQUERADING', false);
      dispatch('setEmail', data.currentUser.user.email, { root: true });
      dispatch('setUserId', data.currentUser.user.id, { root: true });
      dispatch('setVerified', data.currentUser.user.verified, { root: true });

      window.$nuxt.$sensitive.DirectoryAddress.setMasqueradeUserId(null);
      window.$nuxt.$sensitive.DirectoryPerson.setMasqueradeUserId(null);

      for (const key in window.$nuxt.$sensitive.EndOfLifeItem) {
        window.$nuxt.$sensitive.EndOfLifeItem[key].setMasqueradeUserId(null);
      }
      this.$router.push({ path: this.localePath('/admin') });
    } catch (e) {
      console.error(e.message);
    }
  },
};
