export default {
  logoVariant(state, value) {
    state.logoVariant = value;
  },
  navigationVariant(state, value) {
    state.navigationVariant = value;
  },
  setActiveProduct(state, value) {
    state.activeProduct = value || null;
  },
  setActiveSection(state, value) {
    state.activeSection = value || null;
  },
};
