import { mapState, mapActions } from 'vuex';
import { metaArrayToObject, objectToMetaArray } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';

import ADD_CHARITY_MUTATION from '@/graphql/mutations/AddCharity';
import GET_CHARITIES_QUERY from '@/graphql/queries/GetCharities';
import REMOVE_CHARITY_MUTATION from '@/graphql/mutations/RemoveCharity';
import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloCharities',
  apollo: {
    charities: {
      query: GET_CHARITIES_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getCharities,
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      ADD_CHARITY_MUTATION,
      REMOVE_CHARITY_MUTATION,
      charities: [],
    };
  },
  computed: {
    ...mapState(['charitySource']),
    ...mapState('user/contacts', ['userDetails']),
    getCharitiesQuery() {
      return {
        query: GET_CHARITIES_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    isCharity(id) {
      return !!this.charities.filter((charity) => charity.id === id).length;
    },
    async removeCharity(charity) {
      await this.$apollo
        .mutate({
          mutation: this.REMOVE_CHARITY_MUTATION,
          variables: {
            id: charity.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getCharitiesQuery);
            const index = data.getCharities.findIndex(
              (m) => m.id === charity.id
            );

            if (index !== -1) {
              data.getCharities.splice(index, 1);

              store.writeQuery({
                ...this.getCharitiesQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async addPartnerCharity(charity) {
      let exists = false;
      const charityMeta = {
        name: charity.name,
        address: charity.address,
        abn: charity.abn,
        logo: charity.logo,
        referral:
          this.referral_charity ||
          (this.willMeta && this.willMeta.referral_charity),
        suggestionLocation: charity.suggestionLocation,
        suggestionSlot: charity.suggestionSlot,
        searchLocation: charity.searchLocation,
        searchFeature: charity.searchFeature,
        displayName: charity.displayName,
      };

      try {
        const { data } = await this.$apollo.query(this.getCharitiesQuery);
        exists = data.getCharities.find((c) =>
          isSameCharity(metaArrayToObject(c.meta), charity)
        );
      } catch (err) {
        console.error(err.message);
      }

      if (!exists) {
        try {
          await this.$apollo.mutate({
            mutation: ADD_CHARITY_MUTATION,
            variables: {
              willId: this.willId,
              partnershipId: charity.id,
              meta: objectToMetaArray(charityMeta),
            },
            update: (store, { data: { addCharity } }) => {
              if (addCharity) {
                const data = store.readQuery(this.getCharitiesQuery);
                data.getCharities.push(addCharity);

                store.writeQuery({
                  ...this.getCharitiesQuery,
                  data,
                });
              }
            },
          });

          this.$nuxt.$emit('sendTrackingAttributes', {
            charity: this.referral_charity,
          });
        } catch (err) {
          console.error(err.message);
        }
      }
    },
    async replaceNationalCharitiesWithRegionalCharities() {
      const addressState =
        this.userDetails.residentialAddress.region.toUpperCase();
      const referralCharity = this.willMeta.referral_charity;

      if (!referralCharity) {
        return;
      }

      for (const charity of this.charities) {
        const charityNameMeta = charity.meta.find((m) => m.key === 'name');

        if (!charityNameMeta?.value) {
          continue;
        }

        const charityName = charityNameMeta.value.toUpperCase();

        const charitySourceItem = this.charitySource.find((c) => {
          return c.name.toUpperCase() === charityName;
        });

        if (!charitySourceItem) {
          continue;
        }

        const allRegionalCharitySource = this.charitySource.filter((c) => {
          return c.parentPartnershipId === charitySourceItem.id;
        });

        const regionalCharitySourceItem = allRegionalCharitySource.find((c) => {
          return c.region === addressState;
        });

        if (regionalCharitySourceItem) {
          await this.removeCharity(charity); // Remove the national charity
          await this.addPartnerCharity(regionalCharitySourceItem); // Add the local partnership as charity
        }
      }
    },
    async addPartnerCharityWithRelatedCharities(referralCode) {
      // Base on referralCode, we get all "relatedPartnerships" and add each of them into this will
      const charitySource = this.$store.state.charitySource;
      const referralCharitySourceItem = charitySource.find((c) => {
        return c.slug === referralCode;
      });

      if (!referralCharitySourceItem) {
        return;
      }

      const relatedCharitySourceItems = charitySource.filter((c) => {
        return referralCharitySourceItem.relatedPartnershipIds.includes(c.id);
      });

      if (!Array.isArray(relatedCharitySourceItems)) {
        return;
      }

      await Promise.all(
        [referralCharitySourceItem, ...relatedCharitySourceItems].map((c) => {
          return this.addPartnerCharity(c);
        })
      );
    },
    async refetchCharities() {
      await this.$apollo.queries.charities.refetch();
    },
  },
};
