import { mapGetters } from 'vuex';
import { user, will } from '@/mixins/apollo';

export default {
  name: 'MixinsTracking',
  mixins: [user, will],
  computed: {
    ...mapGetters(['email']),
    ...mapGetters('admin/masquerade', ['isMasquerading']),
    ...mapGetters('user/contacts', ['userDetails']),
  },
  watch: {
    email() {
      this.identifyUser();
    },
    userId() {
      this.identifyUser();
    },
  },
  mounted() {
    this.identifyUser();

    this.$nuxt.$on('sendTrackingAttributes', (traits) => {
      const name = this.userDetails?.fullName;

      const mergedTraits = Object.assign(traits, {
        name,
      });

      if (!this.isMasquerading && this.userId) {
        this.$analytics.identify(this.userId, mergedTraits);
      } else {
        // Segment handles Anonymous ID's
        this.$analytics.identify(mergedTraits);
      }
    });

    this.$nuxt.$on(
      'sendTrackingEvent',
      ({ event, props = {}, integrations = {} }) => {
        this.$analytics.track(event, props, {
          integrations,
        });
      }
    );
  },
  beforeDestroy() {
    this.$nuxt.$off('sendTrackingAttributes');
    this.$nuxt.$off('sendTrackingEvent');
  },
  methods: {
    identifyUser() {
      const data = {};
      if (this.willMeta) {
        data.email = this.email;
        data.branch = process.env.BRANCH;
        data.release = process.env.COMMIT_REF;
        data.name = this.userDetails?.fullName;
      }

      if (this.userId) {
        this.$analytics.identify(this.userId, data);
      } else {
        this.$analytics.identify(data);
      }
    },
  },
};
