import { willMetaDefaults } from '@/utilities/constants';

export default () => ({
  data: {
    meta: {
      ...willMetaDefaults,
    },
  },
  gifts: [],
  assets: [],
  liabilities: [],
  executors: [],
  beneficiaries: [],
  guardians: [],
  pets: [],
});
